div.token-main {
  table, td, th {
    border: 1px solid black;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
  }

  .error {
    color: red;
    text-align: center;
  }

  .hide {
    display: none;
  }
}