#donate-toggle-container {
  position: relative;
  z-index: 2;
  p {
    margin: 0px;
  }
  #donate-thumbup {
    position: absolute;
    top: 0px;
    right: 0px;
    #title {
      height: 20px;
      text-align: center;
      background-color: lightgreen;
      width: calc(min(200px, 20vw));
      cursor: pointer;
    }
  }
  #donate-details {
    background-color: aliceblue;
    position: absolute;
    top: 20px;
    right: 0px;
    overflow: hidden;
    transition: height 0.25s ease-in-out, width 0.25s ease-in-out;
    #donate-gratitude {
      overflow: hidden;
    }
    .hide-gratitude-instruct {
      width: calc(min(200px, 20vw));
      max-height: 0px;
    }
    .show-gratitude-instruct {
      padding: 10px 0px;
    }
    .image-thumbup {
      width: calc(min(200px, 20vw));
    }
    .image-detail {
      width: calc(min(500px, 100vw));
    }
    img {
      float: right;
      transition: height 0.25s ease-in-out, width 0.25s ease-in-out;
      cursor: pointer;
    }
  }
  .toggle-more {
    height: calc(min(200px, 20vw));
    &:hover {
      border: 2px solid red;
    }
  }
  .toggle-less {
    height: 0px;
  }
  .show-gratitude {
    height: calc(min(545px, 100vw + 45px));
    width: calc(min(500px, 100vw));
  }
}
